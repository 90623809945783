import {Field, Form, Formik} from 'formik'
import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/RootReducer'
import {UserModel} from '../../modules/auth/models/UserModel'
import {CustomCheckGroup, CustomBaseFormikInput} from '../_components/inputs'
import {getClubs, postUserUpdate} from '../_CRUD'
import {LoadSpan} from '../_components/spans'
import {extraInitials, extraSchema} from '../_INITIALS'
import {errorSwal, successSwal} from '../_components/swal'
import * as auth from '../../modules/auth/redux/AuthRedux'
import {PageCard} from '../_components/cards'

const ExtraInfoPage: FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()

  const clubs = useQuery(
    'clubs',
    () => getClubs().then((res) => res.data),
    {staleTime: 1000 * 60 * (10 + 0)} // 10 min in ms
  )

  return (
    <>
      <Formik
        initialValues={{...extraInitials, ...user}}
        validationSchema={extraSchema(intl)}
        onSubmit={(values) => {
          console.log(values)
          setLoading(true)
          postUserUpdate(values)
            .then((res) => {
              dispatch(auth.actions.setUser(values))
              successSwal(
                intl.formatMessage(
                  {id: 'GENERAL.SUCCESSFUL_X'},
                  {action: intl.formatMessage({id: 'GENERAL.SAVED'})}
                )
              )
            })
            .catch((e) => errorSwal(e)) //TODO if server return 500. No exception is thrown. Bug?
            .finally(() => setLoading(false))
        }}
      >
        <Form>
          <PageCard>
            <div className='row'>
              <div className='col-md-6 mb-6'>
                <Field
                  name='club'
                  label={intl.formatMessage({id: 'GENERAL.CLUB'})}
                  isLoading={clubs.isLoading}
                  tag='react-select'
                  component={CustomBaseFormikInput}
                  options={clubs.data?.map((el) => ({
                    value: el.name,
                    label: el.name,
                  }))}
                />
              </div>
              <div className='col-md-6 mb-6'>
                <Field
                  name='club_other'
                  label={intl.formatMessage({id: 'EXTRA.OTHER_CLUB'})}
                  component={CustomBaseFormikInput}
                />
              </div>
            </div>
          </PageCard>

          <PageCard>
            <div className='row'>
              <div className='col-md-6'>
                <div className='mb-6'>
                  <CustomCheckGroup
                    name='boat_license'
                    label={intl.formatMessage({id: 'EXTRA.BOAT_LICENSE'})}
                    options={[
                      {
                        label: intl.formatMessage({id: 'EXTRA.BOAT_LICENSE.BINNEN'}),
                        value: 'Sportbootführerschein Binnen',
                      },
                      {
                        label: intl.formatMessage({id: 'EXTRA.BOAT_LICENSE.LAKE'}),
                        value: 'Sportbootführerschein See',
                      },
                      {
                        label: intl.formatMessage({id: 'EXTRA.BOAT_LICENSE.BINNENxLAKE'}),
                        value: 'Sportbootführerschein Binnen und See',
                      },
                    ]}
                    tag='radio'
                  />
                </div>

                <div className='mb-6'>
                  <CustomCheckGroup
                    name='size'
                    label={intl.formatMessage({id: 'EXTRA.TSHIRT'})}
                    options={[
                      {label: 'XS', value: 'XS'},
                      {label: 'S', value: 'S'},
                      {label: 'M', value: 'M'},
                      {label: 'L', value: 'L'},
                      {label: 'XL', value: 'XL'},
                      {label: 'XXL', value: 'XXL'},
                    ]}
                    tag='radio'
                    required={true}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-6'>
                <CustomCheckGroup
                  name='drivers_license'
                  label={intl.formatMessage({id: 'EXTRA.DRIVERS_LICENSE'})}
                  options={[
                    {label: 'AM', value: 'AM'},
                    {label: 'A1', value: 'A1'},
                    {label: 'A2', value: 'A2'},
                    {label: 'A', value: 'A'},
                    {label: 'B', value: 'B'},
                    {label: 'BF17', value: 'BF17'},
                    {label: 'BE', value: 'BE'},
                    {label: 'B96', value: 'B96'},
                    {label: 'C', value: 'C'},
                    {label: 'CE', value: 'CE'},
                    {label: 'C1', value: 'C1'},
                    {label: 'C1E', value: 'C1E'},
                    {label: 'D', value: 'D'},
                    {label: 'DE', value: 'DE'},
                    {label: 'D1', value: 'D1'},
                    {label: 'D1E', value: 'D1E'},
                    {
                      label: intl.formatMessage({id: 'EXTRA.DRIVERS_LICENSE.FORKLIFT'}),
                      value: 'Gabelstaplerschein',
                    },
                    {
                      label: intl.formatMessage({id: 'EXTRA.DRIVERS_LICENSE.PASSENGER'}),
                      value: 'Personenbeförderungsschein',
                    },
                  ]}
                  tag='checkbox'
                />
              </div>
            </div>
          </PageCard>

          <PageCard>
            <div className='row'>
              <div className='col-md-4 mb-6'>
                <CustomCheckGroup
                  name='skills_special'
                  label={intl.formatMessage({id: 'EXTRA.SKILLS'})}
                  tag='checkbox'
                  options={[
                    {label: intl.formatMessage({id: 'EXTRA.SKILLS.LANGUAGES'}), value: 'Sprachen'},
                    {
                      label: intl.formatMessage({id: 'EXTRA.SKILLS.COMMUNICATION'}),
                      value: 'Kommunikationsfähigkeiten',
                    },
                    {label: intl.formatMessage({id: 'EXTRA.SKILLS.EDP'}), value: 'EDV'},
                    {
                      label: intl.formatMessage({id: 'EXTRA.SKILLS.CRAFTING'}),
                      value: 'handwerkliche Kenntnisse',
                    },
                    {
                      label: intl.formatMessage({id: 'EXTRA.SKILLS.LEADING'}),
                      value: 'Führungserfahrung',
                    },
                    {
                      label: intl.formatMessage({id: 'EXTRA.SKILLS.FIRSTAID'}),
                      value: 'Erste Hilfe',
                    },
                    {
                      label: intl.formatMessage({id: 'EXTRA.SKILLS.MEDICAL'}),
                      value: 'medizinische Fachkraft',
                    },
                    {
                      label: intl.formatMessage({id: 'EXTRA.SKILLS.PHYSIO'}),
                      value: 'Physiotherapeut',
                    },
                    {label: intl.formatMessage({id: 'EXTRA.SKILLS.OTHER'}), value: 'weiteres'},
                  ]}
                ></CustomCheckGroup>
              </div>
              <div className='col-md-4 mb-6'>
                <Field
                  name='skills_other'
                  label={intl.formatMessage({id: 'EXTRA.SKILLS_OTHER'})}
                  tag='textarea'
                  component={CustomBaseFormikInput}
                />
              </div>
            </div>
          </PageCard>

          <PageCard>
            <div className='row'>
              {/* <div className='col-md-6 mb-6'>
                <CustomCheckGroup
                  tag='checkbox'
                  name='application_areas'
                  label={intl.formatMessage({id: 'APPLICATION.GENERAL.TITLE'})}
                  options={[
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.BOAT_DRIVER'}),
                      value: 'bootsfahrer',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.BOAT_SITE'}),
                      value: 'bootsplatz',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.INFO'}),
                      value: 'information',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.INFRASTRUCTURE'}),
                      value: 'infrastruktur',
                    },
                    {label: intl.formatMessage({id: 'APPLICATION.AREAS.IT'}), value: 'it'},
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.MEDICAL'}),
                      value: 'medical',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.MEDIA_TECH'}),
                      value: 'medientechnik',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.REGATTA'}),
                      value: 'regattabetrieb',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.SECURITY'}),
                      value: 'sicherheit',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.WINNER_HONORING'}),
                      value: 'siegerehrung',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.SOCIAL_MEDIA'}),
                      value: 'social_media',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.SPRINGER'}),
                      value: 'springer',
                    },
                    {label: intl.formatMessage({id: 'APPLICATION.AREAS.START'}), value: 'start'},
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.TRANSPORT'}),
                      value: 'transport',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.CATERING'}),
                      value: 'verpflegung',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.VOLUNTEERS'}),
                      value: 'volunteers',
                    },
                    {
                      label: intl.formatMessage({id: 'APPLICATION.AREAS.TIMEKEEPING'}),
                      value: 'zeitnahme',
                    },
                  ]}
                />
              </div> */}

              <div className='col-md-6 mb-6'>
                <Field
                  name={'application_areas_remarks'}
                  label={intl.formatMessage({id: 'APPLICATION.GENERAL.REMARKS'})}
                  component={CustomBaseFormikInput}
                  tag='textarea'
                />
              </div>

              <div className='col-md-6 mb-6'>
                <Field
                  name={'application_areas_so_far'}
                  label={intl.formatMessage({id: 'APPLICATION.GENERAL.SO_FAR'})}
                  component={CustomBaseFormikInput}
                  tag='textarea'
                />
              </div>

              <div className='col-md-6 mb-6'>
                <Field
                  name={'events_so_far'}
                  label={intl.formatMessage({id: 'APPLICATION.GENERAL.EVENTS.SO_FAR'})}
                  component={CustomBaseFormikInput}
                  tag='textarea'
                />
              </div>
            </div>
          </PageCard>

          <PageCard>
            <div className='row'>
              <CustomCheckGroup
                name='eating_preferences'
                label={intl.formatMessage({id: 'EXTRA.EATING_PREFERENCE'})}
                options={[
                  {
                    label: intl.formatMessage({id: 'EXTRA.EATING_PREFERENCE.VEGETARIAN'}),
                    value: 'Vegetarisch',
                  },
                  {
                    label: intl.formatMessage({id: 'EXTRA.EATING_PREFERENCE.VEGETARIAN.NOT'}),
                    value: 'Nicht-Vegetarisch',
                  },
                  {
                    label: intl.formatMessage({id: 'EXTRA.EATING_PREFERENCE.FOOD.NOT'}),
                    value: 'kein Essen',
                  },
                ]}
                tag='radio'
              />
            </div>
          </PageCard>

          <PageCard>
            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && intl.formatMessage({id: 'GENERAL.SAVE'})}
                {loading && <LoadSpan />}
              </button>
            </div>
          </PageCard>
        </Form>
      </Formik>
    </>
  )
}

export default ExtraInfoPage
