/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {getCheckedEvents, getEvents, getJobs, setCheckedEvents} from '../_CRUD'
import {CustomModal} from '../_components/modals'
import {EVENT, JOB} from '../_TYPES'
import {Form, Formik} from 'formik'
import {useIntl} from 'react-intl'
import {LoadSpan} from '../_components/spans'
import {ICalendar} from 'datebook'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {CustomCheckGroup} from '../_components/inputs'

type EventCardProps = {
  bannerURL?: any
  width?: number
  style?: any
  customHeader?: any
  btn_text?: string
  btn_onclick?: any
  btn_disabled?: boolean
  customFooter?: any
}

const Card: React.FC<EventCardProps> = ({
  children,
  style,
  bannerURL,
  customHeader,
  btn_text,
  btn_onclick = () => alert('todo'),
  btn_disabled = false,
  customFooter,
}) => {
  return (
    <div
      className='card card-custom card-stretch card-flush shadow mb-5 m-2'
      style={{height: '300px', ...style}}
    >
      {customHeader ? (
        customHeader
      ) : (
        <div
          className='card-header'
          style={{
            backgroundImage: `url('${bannerURL}')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
            height: '200px',
          }}
        />
      )}

      <div className='card-body'>{children}</div>
      <div className='card-footer p-2'>
        <div className='d-flex justify-content-between align-items-center'>
          {customFooter}
          <button
            className='btn btn-primary btn-sm m-0 mx-2'
            onClick={btn_onclick}
            disabled={btn_disabled}
          >
            {btn_text}
          </button>
        </div>
      </div>
    </div>
  )
}

export const StartPage: React.FC = () => {
  const intl = useIntl()
  const lang = useLang()

  const formId = 'form_start_shifts'

  const [modal_event, setModalEvent] = useState<EVENT>()
  const [modal_state, toggleModal] = useState(false)
  const [modal_checked, setChecked] = useState({})

  const [btn_loading, toggleBtnLoading] = useState(false)
  const [modal_loading, toggleModalLoading] = useState(false)

  const events = useQuery(
    'events',
    () => getEvents().then((res) => res.data),
    {staleTime: 1000 * 60 * (10 + 0)} // 10 min in ms
  )

  const jobs = useQuery('jobs', () => getJobs().then((res) => res.data), {
    staleTime: 1000 * 60 * (10 + 1), // 11 min in ms
    enabled: !!events.data,
  })

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap'>
        <div className='col-md-8 col-12'>
          <div className='d-flex flex-wrap'>
            {events.isLoading && <LoadSpan />}
            {events.error && 'An error occurred while loading the data'}

            {events.data &&
              events.data.map((el: EVENT) => (
                <div className='col-md-6 col-12'>
                  <Card
                    bannerURL={el.banner}
                    customFooter={
                      <>
                        <span
                          className='bullet mx-2 h-30px w-30px'
                          style={{backgroundColor: el.color_code}}
                        />
                        <span className='mx-2 px-2'>
                          {el.from === el.to
                            ? intl.formatDate(new Date(el.from))
                            : `${intl.formatDate(new Date(el.from))} - ${intl.formatDate(
                                new Date(el.to)
                              )}`}
                        </span>
                      </>
                    }
                    btn_text={intl.formatMessage({id: 'START.EVENT.SHOW'})}
                    btn_onclick={() => {
                      setModalEvent(el)
                      toggleModalLoading(true)
                      toggleModal(true)
                      setChecked([])

                      getCheckedEvents(el.id)
                        .then((result) => setChecked(result.data))
                        .finally(() => toggleModalLoading(false))
                    }}
                  >
                    <h4 className='m-0'>{el.title}</h4>
                  </Card>
                </div>
              ))}
          </div>
        </div>

        <div className='col-md-4 col-12'>
          <Card
            style={{height: 'auto', with: '100%'}}
            customFooter={<div></div>}
            customHeader={
              <div className='card-header'>
                <h3 className='card-title'>{intl.formatMessage({id: 'START.EVENTS.YOURS'})}</h3>
              </div>
            }
            btn_text={intl.formatMessage({id: 'GENERAL.DOWNLOAD'}, {file: 'ICS'})}
            btn_onclick={() => {
              const events = jobs.data?.map((job: JOB) => ({
                title: job.title,
                start: new Date(job.date + ' ' + job.from),
                end: new Date(job.date + ' ' + job.to),
              }))

              if (events?.length) {
                const ical = new ICalendar(events[0])

                events.slice(1).forEach((event) => {
                  ical.addEvent(new ICalendar(event))
                })

                ical.download('events.ics')
              }
            }}
            btn_disabled={jobs.data ? !(jobs.data.length > 0) : true}
          >
            {jobs.isLoading && <LoadSpan />}
            {jobs.data && jobs.data.length ? (
              jobs.data.map((el) => (
                <div className='d-flex align-items-center mb-8'>
                  <span
                    className='bullet bullet-vertical h-40px w-10px'
                    style={{backgroundColor: el.color_code}}
                  ></span>
                  <div className='flex-grow-1 mx-2'>
                    <span className='text-gray-800 fw-bolder fs-6'>{el.title}</span>
                    <span className='text-gray-800 fs-6'>{el.job ? ': ' + el.job : ''}</span>
                    <span className='text-muted fw-bold d-block'>
                      {intl.formatRelativeTime(
                        Math.floor(
                          (new Date(el.date).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
                        ),
                        'day'
                      )}
                      : {intl.formatDate(new Date(el.date))} {el.from.slice(0, 5)} -{' '}
                      {el.to.slice(0, 5)}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div>{intl.formatMessage({id: 'ERROR.NO_DATA'})}</div>
            )}
          </Card>
        </div>
      </div>

      <CustomModal
        show={modal_state}
        title={modal_event ? modal_event.title : ''}
        submitFormId={formId}
        handleClose={() => toggleModal(false)}
        btn_loading={btn_loading}
      >
        <Formik
          initialValues={modal_checked}
          enableReinitialize={true}
          onSubmit={(values) => {
            setCheckedEvents(modal_event?.id as string, values).then(() => {
              toggleModal(false)
              toggleBtnLoading(false)
            })
          }}
        >
          <Form id={formId}>
            {modal_loading && <LoadSpan />}

            {!modal_loading && (
              <>
                <div className='row'>
                  <div className='col-md-6 mb-6'>
                    <CustomCheckGroup
                      name='checked_times'
                      label={intl.formatMessage({id: 'START.DATE.SLOTS'})}
                      tag='checkbox'
                      // required={true}
                      options={modal_event?.shifts.map((el) => ({
                        label: ` ${intl.formatDate(new Date(el.date))}: ${
                          el.time === 'V'
                            ? intl.formatMessage({id: 'START.DATE.MORNING'})
                            : intl.formatMessage({id: 'START.DATE.AFTERNOON'})
                        }`,
                        value: `${el.date}_${el.time}`,
                      }))}
                    />
                  </div>

                  <div className='col-md-6 mb-6'>
                    {modal_event?.applications.length ? (
                      <CustomCheckGroup
                        name='checked_appl'
                        label={intl.formatMessage({id: 'MENU.APPLICATION'})}
                        tag='checkbox'
                        // required={true}
                        options={modal_event.applications.map((el) => ({
                          label: el.label[lang],
                          value: el.id,
                        }))}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {(modal_event?.is_overnight || modal_event?.is_tent) && (
                  <>
                    <div className='separator border-solid mb-6' />

                    <div className='row'>
                      {modal_event?.is_overnight && (
                        <div className='col-md-6 mb-6'>
                          <CustomCheckGroup
                            name='overnight'
                            label={intl.formatMessage({id: 'EXTRA.OVERNIGHT'})}
                            options={[
                              {label: intl.formatMessage({id: 'GENERAL.YES'}), value: 'Ja'},
                              {label: intl.formatMessage({id: 'GENERAL.NO'}), value: 'Nein'},
                            ]}
                            tag='radio'
                            required={true}
                          />
                        </div>
                      )}

                      {modal_event?.is_tent && (
                        <div className='col-md-6 mb-6'>
                          <CustomCheckGroup
                            name='tent'
                            label={intl.formatMessage({id: 'EXTRA.TENT'})}
                            options={[
                              {label: intl.formatMessage({id: 'GENERAL.YES'}), value: 'Ja'},
                              {label: intl.formatMessage({id: 'GENERAL.NO'}), value: 'Nein'},
                            ]}
                            tag='radio'
                            required={true}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </Form>
        </Formik>
      </CustomModal>
    </>
  )
}
